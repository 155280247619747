<template>
    <v-row class="d-flex justify-center">
        <v-col cols="12" sm="8" md="10" lg="6" xl="4">
            <v-form ref="form">
                <v-card class="xs-margin xs-padding sm-padding card-shadow bg-white">
                    <h1 class="text-h1 font-weight-600 text-center mb-2 pt-5">Welcome Back!</h1>
                    <p class="text-light font-size-root text-center font-weight-thin mb-8">Please login to your account.</p>
                    <v-card-text class="font-size-root">
                        <label for="email" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Email</label>
                        <v-text-field
                            :rules="[rules.required, rules.email]"
                            id="email"
                            autocomplete="off"
                            hide-details="auto"
                            validate-on-blur
                            v-model="email"
                            type="text"
                            outlined
                            class="input-style font-size-input text-color-gray mt-0 mb-4"
                            placeholder="johndoe@gmail.com">
                        </v-text-field>
                        <label for="password" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Password</label>
                        <v-text-field
                            :rules="[rules.required, rules.password]"
                            id="password"
                            autocomplete="off"
                            hide-details="auto"
                            validate-on-blur
                            v-model="password"
                            outlined
                            class="input-style font-size-input text-color-gray mt-0 mb-4"
                            placeholder="********"
                            :type="showPassword ? 'text' : 'password'">
                            <template slot="append">
                                <feather :type="showPassword ? 'eye' : 'eye-off'" size=".875rem" stroke-width="1.5" color="#adb5bd" @click="showPassword = !showPassword" class="eye"> </feather>
                            </template>
                        </v-text-field>
                        <div class="d-flex justify-end mt-4">
                            <router-link to="forgot-password" class="text-decoration-none">
                                <span class="text-primary subtitle-2">Forgot Password?</span>
                            </router-link>
                        </div>
                        <v-btn @click="signIn" elevation="0" block :ripple="true" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6" color="#6F70FF">
                            Login
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-form>

            <div v-if="configs.length < 1" class="d-flex justify-center mt-4">
                <small class="text-light"
                    >Don't have an account?
                    <router-link to="create-account" class="text-decoration-none">
                        <span class="text-primary">Create one.</span>
                    </router-link>
                </small>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import { authManagement } from '@/store/api';
import { makeFindMixin } from 'feathers-vuex';
import isMobile from '../../plugins/isMobile';

export default {
    name: 'login',
    data() {
        return {
            isMobile: isMobile(),
            email: '',
            password: '',
            checkbox: false,
            showPassword: false,
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    return pattern.test(value) || 'Invalid e-mail.';
                },
                password: (value) => {

                    let atLeast10Chars = value.length >= 10;
                    let matchRestPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/g;
                    let matchRest = matchRestPattern.test(value);

                    return (atLeast10Chars && matchRest) || 'Password must be at least 10 characters, with lower- and uppercase letters, at least one number, and a special character';

                    // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
                    // return pattern.test(value) || 'Password must be at least 10 characters, with lower- and uppercase letters, at least one number, and a special character';
                }
            }
        };
    },
    mixins: [makeFindMixin({ service: 'configs' })],
    mounted() {
        if (this.token) this.verify();
    },
    methods: {
        async signIn() {
            if (this.$refs.form.validate()) {
                try {
                    await this.authenticate({
                        strategy: 'local',
                        email: this.email,
                        password: this.password
                    });

                    this.showSuccess('Successfully Logged in!');

                    if (isMobile) {
                        this.$router.push('/my-forms');
                    } else {
                        this.$router.push('/dashboard/overview');
                    }
                } catch (error) {
                    this.showError(error);
                }
            }
        },
        async verify() {
            try {
                await authManagement.create({
                    action: 'verifySignupLong',
                    value: this.token
                });

                this.showSuccess('Account verified!');
            } catch (error) {
                this.showError(error);
            }
        },
        ...mapActions('apiAuth', ['authenticate', 'logout']),
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        token() {
            return (this.$route.query && this.$route.query.token) || '';
        },
        configsParams() {
            return {
                query: {}
            };
        }
    }
};
</script>

<style lang="scss">
.eye {
    &:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 599px) {
    .xs-margin {
        margin: 0 10px;
    }

    .xs-padding {
        padding: 10px;
    }
}

@media screen and (min-width: 600px) {
    .sm-padding {
        padding: 40px;
    }
}
</style>
